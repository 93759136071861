<template>
    <div>
      
        <img src="@/assets/img/home/portada.jpg" class="w-100 h-100 img-background" alt="Novios abrazados">
       
      <HomeCard v-for="(card, index) in cards" :datos="card" :key="index" :id="'home-card-'+index" class="mvh-lg-100" />
    </div>
  </template>
  <script>
    
    import HomeCard from '@/components/home/Card.vue'
    import Img1 from '@/assets/img/home/15 años/2.jpg'
    import Img1_2 from '@/assets/img/home/2-1.png'
    import Img1_3 from '@/assets/img/home/2-2.png'
  
    import Img2 from '@/assets/img/home/15 años/3.jpg'
    import Img2_2 from '@/assets/img/home/3-1.png'
  
    import Img3 from '@/assets/img/home/15 años/4.jpg'
    import Img3_2 from '@/assets/img/home/4-1.png'
    
    export default {
      name: 'Home',
      components: {
        
        HomeCard
      },
      data(){
        return {
          cards: [{
            title: 'Celebra con Nosotros tus 15 años',
            descripcion: "Quince años de sueños, quince años de risas, quince años de pura magia. Únete a nosotros mientras celebramos un momento único en la vida. ¡Es su gran día y queremos que brille como nunca! Descubre la belleza de esta noche mágica mientras creamos recuerdos que durarán para siempre. ¡No te pierdas la celebración de los quince años, Un evento que capturará corazones y dejará huellas en el alma. ¡Ven y únete a la fiesta que solo ocurre una vez en la vida!",
            img1: Img1,
            img2: Img1_2,
            img3: Img1_3,
            bg: 'bg-primary text-white',
            align: 'end',
            url: 'como-funciona.index',
            urlname: 'Como funciona',
            urlClass: 'btn-light',
            hide: true
          },
          {
            title: 'Aprovecha cada Momento',
            descripcion: ' "Los quince años marcan un punto de inflexión en la vida de toda joven, un momento lleno de simbolismo y significado. Es un paso hacia la madurez, un momento en el que los sueños se entrelazan con la realidad y las promesas del futuro se hacen más tangibles. Es un tiempo para celebrar el crecimiento, la belleza y la fuerza interior que han florecido a lo largo de los años.Los quince años representan la transición de la niñez a la juventud, un momento para honrar las experiencias vividas y anticipar con emoción las aventuras por venir. Es una ocasión para reflexionar sobre el camino recorrido, agradecer a quienes han estado presentes en cada paso y mirar hacia adelante con optimismo y determinación.',
            img1: Img2,
            img2: Img2_2,
            bg: 'bg-secondary text-white',
            align: 'start',
            hide: true,
          },
          {
            title: 'Comparte',
            descripcion: 'En esta celebración, no solo conmemoramos los quince años de una joven especial, sino que también celebramos su individualidad, su valentía y su capacidad para enfrentar el mundo con gracia y elegancia. Es un momento para rodearla de amor, apoyo y alegría, y para desearle todo lo mejor en su viaje hacia el futuro. Que esta noche sea un recordatorio de que cada momento es precioso, que cada sueño es alcanzable y que el amor y la familia son el verdadero tesoro en la vida de una quinceañera.',
            img1: Img3,
            img2: Img3_2,
            bg: 'bg-light text-dark',
            align: 'end',
            hide: true
          }]
        }
      },
      mounted(){
        window.addEventListener('scroll', this.handleScroll);
      },
      unmounted(){
        window.removeEventListener('scroll', this.handleScroll)
      },
      methods: {
        handleScroll () {
          for (let index = 0; index < this.cards.length; index++) {
            var top = document.getElementById('home-card-'+index).offsetTop
            if(document.documentElement.scrollTop >= (top-20)){
              if(this.cards[index]['hide']){
                this.cards[index]['hide'] = false
              }
            }
          }
        }
      }
    }
  </script>
    